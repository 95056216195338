var ts = new Date().getTime();
$(document).ready(function () {
    $.when(
        $.getJSON('/webcam-directory.json', function (data) {
            $.each(data['cams'], function (i, f) {
                $('#cam-gallery').append(
                    '<div class="column"><a href="' + f.image_url + '" target="_blank"><img class="ui fluid rounded image" src="/build/images/loading.gif" data-url="' + f.image_url + '?rand=' + ts + '" title="' + f.description + '"></a></div>'
                );
            });
        })
    ).done(function () {
        $('#cam-gallery img').each(
            function () {
                var newSrc = $(this).attr('data-url');
                var img = new Image();
                img.src = newSrc;
                img.swap = $(this);
                img.onload = function () {
                    this.swap.attr('src', this.src);
                    return true;
                };
                img.onerror = function () {
                    return true;
                };
                img.src = newSrc;
            }
        );
    });
});